<template>
  <loading-dialog :loading="$apollo.loading">
    <!-- email and dns config -->
    <email-config />

    <!-- sms credentials -->
    <credentials />
  </loading-dialog>
</template>

<script>
export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    EmailConfig: () => import('@/modules/accounts/components/edit/EmailConfig.vue'),
    Credentials: () => import('@/modules/accounts/components/edit/Credentials.vue')
  }
}
</script>
